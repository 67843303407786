<template>
    <!--МЭДЭЭ, МЭДЭЭЛЭЛ ДЭЛГЭРЭНГҮЙ-->
    <div class="medee_medeelel_delgerengui">
        <div class="container">
            <div class="row">
            </div>
            <div class="row ROW_1">
                <div class="col-md-8">
                    <div class="medee_medeelel_delgerengui_slide_L">
                        <h5 v-if="language.short == 'mn'">{{ news.garchig_mn }}</h5>
                        <h5 v-else>{{ news.garchig_en }}</h5>
                        <div class="time"><span>{{ news.updated_at |date }}</span></div>
                        <div class="right_banner">
                            <div class="b_photo">
                                <a href="">
                                    <img :src="image_url_mbua + news.zurag" class="photo">
                                </a>
                            </div>
                        </div>
                        <p class="p_text" v-if="language.short == 'mn'" v-html="news.medee_mn"></p>
                        <p class="p_text" v-else v-html="news.medee_en"></p>
                        <div class="socials">
                            <ShareNetwork
                                    v-if="language.short == 'mn'"
                                    network="facebook"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.garchig_mn"
                                    :description="news.medee_mn"
                                    :quote="news.garchig_mn"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-facebook"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-else
                                    network="facebook"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.garchig_en"
                                    :description="news.medee_en"
                                    :quote="news.garchig_en"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-facebook"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-if="language.short == 'mn'"
                                    network="twitter"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.garchig_mn"
                                    :description="news.medee_mn"
                                    :quote="news.garchig_mn"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-twitter"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-else
                                    network="twitter"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.garchig_en"
                                    :description="news.medee_en"
                                    :quote="news.garchig_en"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-twitter"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-if="language.short == 'mn'"
                                    network="linkedIn"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.garchig_mn"
                                    :description="news.medee_mn"
                                    :quote="news.garchig_mn"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-linkedin"></i>
                            </ShareNetwork>
                            <ShareNetwork
                                    v-else
                                    network="linkedIn"
                                    :url="`https://job-mnca.mn/${news.id}`"
                                    :title="news.garchig_en"
                                    :description="news.medee_en"
                                    :quote="news.garchig_en"
                                    hashtags="MNCA"
                            >
                                <i class="ivu-icon ivu-icon-logo-linkedin"></i>
                            </ShareNetwork>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="news">
                        <div class="another-news">
                            <ul class="list-post">
                                <li v-for="(list, index) in News" :key="index" class="clearfix">
                                    <router-link :to="'/news_sub/' + list.id" style="text-decoration: none">
                                        <div class="post-block-style post-float clearfix">
                                            <div class="post-thumb">
                                                <router-link :to="'/news_sub/' + list.id"
                                                   class="router-link-exact-active router-link-active">
                                                    <img :src="image_url_mbua + list.zurag" class="img-fluid">
                                                </router-link>
                                            </div>
                                            <div class="post-content">
                                                <h2 class="post-title title-small">
                                                    <a v-if="language.short == 'mn'" href="" aria-current="page"
                                                       class="router-link-exact-active router-link-active">
                                                        {{list.garchig_mn}}
                                                    </a>
                                                    <a v-else href="" aria-current="page"
                                                       class="router-link-exact-active router-link-active">
                                                        {{list.garchig_en}}
                                                    </a>
                                                </h2>
                                                <div class="post-meta">
                                                    <span class="post-date">{{ list.updated_at | date }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {GET_NEWS_BY_ID_DETAILS, GET_NEWS_BY_ID_DETAILS_LIST} from "../../graphql/queries";
import {mapGetters} from "vuex";
import {IMAGE_URL} from "../../graphql/queries";

export default {
    name: "news_sub",
    props: ['id'],
    data() {
        return {
            // selectId: null,
            news: {},
            image_url_mbua: IMAGE_URL,
            News: []
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ])
    },
    mounted() {
        this.getNewsList();
        this.getNewsDetailsList();
    },
    methods: {
        getNewsList() {
            this.$apollo.query({
                query: GET_NEWS_BY_ID_DETAILS,
                variables: {news_id: this.id.toString()}
            }).then(({data}) => {
                this.news = data.ds_medee_view[0];

            })
        },
        getNewsDetailsList() {
            this.$apollo.query({
                query: GET_NEWS_BY_ID_DETAILS_LIST,
            }).then(({data}) => {
                this.News = data.ds_medee_view;
            })
        }

    },

}
</script>

<style scoped>

</style>

